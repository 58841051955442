export default {
  ORGANIZATIONS_LIST: "Camping List",
  ADD_ORGANIZATION: "Add an Camping",
  ORGANIZATION_ADDED: "Camping Added",
  OWNER: "Owner",
  EDIT_ORGANIZATION: "Edit an Camping",
  ORGANIZATION_UPDATED: "Camping Updated",
  SELECT_A_PACKAGE: "Select a Package",
  ADD_SUBSCRIPTION: "Add a Subscription",
  SUBSCRIPTION_INVOICE_ADDED: "Subscription Invoice Added",
  AUTHORIZATIONS: "Authorizations",
  DELETE_THIS_ORGANIZATION: "Delete this Camping?",
  ORGANIZATION_DELETED: "Camping Deleted",
  VIEW_ELIXIO_SYNC: "Elixio Synch",
};
