export default {
  BOOKING_LIST: "Reservations",
  TITLE: "Reservation",
  PENDING_BOOKING_LIST: "List of pending reservations",
  ADD_BOOKING: "Add reservation",
  BOOKING_ADDED: "Reservation confirmed",
  EDIT_BOOKING: "Modify reservation",
  BOOKING_UPDATED: "Reservation updated",
  DELETE_THIS_BOOKING: "Delete this reservation?",
  BOOKING_DELETED: "Reservation deleted",
  ADULTS_CAPACITY: "Adultes",
  PETS_CAPACITY: "Pets",
  CHILDREN_CAPACITY: "Children",
  BOOK_SPOT: "Book a spot",
  STARTING_FROM: "Starting from",
  PRICE_PER_NIGHT: "per night",
  AVAILABILITY: "Availability",
  AVAILABLE: "Available",
  NOT_AVAILABLE: "Not available",
  NOT_AVAILABLE_ERROR: "This spot is not available",
  PARTIALLY_AVAILABLE: "Partially available",
  FILTER_BY_SPOT_CATEGORY: "Pitch or site",
  FILTER_BY_SPOT_TYPE: "Service",
  FILTER_BY_SPOT_LENGTH: "Equipment dimensions",
  FILTER_BY_EQUIPMENT: "Equipment",
  UNIT_FEET: "feet",
  SPOTS_LIST_FOUND: "{total} sites found",
  BOOKING_CODE: "Confirmation code",
  DURATION: "Number of nights",
  PRICE_PER_DAY: "Price/day",
  ARRIVING_AT: "Arrival",
  LEAVING_AT: "Leaving",
  EXISTING_CUSTOMER: "Client existant",
  CREATE_CUSTOMER: "Nouveau client",
  SECURE_BOOKING: "Secure",
  ADD_TO_CART: "Add",
  SPECIAL_REQUEST: "Customer special request",
  INTERNAL_NOTE: "Internal note",
  SELECT_CONFIRMATION_EXPIRATION_DATE:
    "Select an expiration date for confirmation",
  SEND_TO_CUSTOMER_EMAIL: "Send to customer's email address",
  CONFIRM_AND_SEND: "Confirm and send",
  BLOCK_THIS_SPOT: "Block this spot",
  VIEW_BOOKING: "View booking",
  PENDING: "Pending",
  BOOKINGS_LIST: "Bookings list",
  BOOKING_DETAILS: "Booking details",
  BOOKING_NUMBER: "Booking Number",
  BOOKING_DAYS: "Reserved days",
  BOOKING_SPOT: "Booking spot",
  BOOKING_INFORMATION: "Booking information",
  OCCUPATION: "Occupation",
  SPOT_INFORMATION: "Spot information",
  SELECTED_PERIOD: "Selected period",
  SERVICE: "Service",
  CONFIRM_THIS_BOOKING:
    "Do you really want to confirm and send this reservation to <span class='text-muted'>{email}</span>?",
  CONFIRM_BOOKING: "Confirmation",
  DATE_RANGE_ALERT: "Please choose 2 different dates for your reservation.",
  BOOKING_CONFIRMED: "Reservation confirmed",
  CONFIRMED: "No warranty",
  ARRIVED: "Arrived",
  FINISHED: "Arrived",
  TOTAL_BOOKINGS: "{total} reservations",
  CONFORM_PAYMENT: "Confirm payment",
  BOOKING_IDENTIFIER: "# Booking {code}",
  BOOKING_DATE: "Booking date",
  MY_CURRENT_BOOKINGS: "My bookings list",
  OTHERS_CURRENT_BOOKINGS: "Others bookings",
  CONFIRM_THE_BOOKING: "Confirm the booking",
  BOOK_WITH_PAYMENT: "Book with payment",
  BOOK_WITHOUT_PAYMENT: "Book without payment",
  TAKE_CONTROL_OF_BOOKING:
    "<p>Another user ({email}) is working on this booking</p><br> <b>Do you still want to take control?</b>",
  BEDS_QTY: "Number of beds",
  ROOMS_QTY: "Number of rooms",
  SELECTED_EQUIPMENT: "Selected equipment",
  REASON_UNAVAILABLE: "Unavailable",
  SELECT_DATE_RANGE: "Select date range",
  CHECK_IN: "Check in",
  CHECK_OUT: "Check out",
  CANNOT_CHECK_OUT: "You can't put your reservation to exit !",
  CANNOT_CHECK_OUT_MESSAGE: "You cannot put your reservation on departure more than {0} h before the scheduled departure date.",
  CANNOT_CHECK_IN: "Unable to check in your reservation !",
  CANNOT_CHECK_IN_MESSAGE: "You cannot place your reservation for arrival more than {0} before the scheduled arrival date !",
  CHECK_IN_THIS_BOOKING: "Do you really want to check in this booking ?",
  CHECK_OUT_THIS_BOOKING: "Do you really want to check out this booking ?",
  GUARANTEED_RESERVATION: "Guaranteed reservation",
  NOT_GUARANTEED_RESERVATION: "Not guaranteed reservation",
  CANT_CANCEL: "The cancellation policies do not allow you to cancel this reservation.",
  CANCEL_FEE: "Cancellation Fee: {0}",
  REFUND_AMOUNT: "Refund Amount: {0}",
  BALANCE_OUTSTANDING_AMOUNT: "Balance due: {0}",
  CANCELLATION_WITH_FEES: `<br/>
                           <span>You are about to cancel this reservation, fees may apply.</span>
                           <br/> <a href="{0}">See cancellation policy.</a>`,
  GUARANTEED: "Guaranteed",
  NOT_GUARANTEED: "Not guaranteed",
  IN_PROGRESS_RESERVATION: "Reservation in progress",
  CANCELED: "Cancelled",
  OUT_OF_SERVICE: "Out of service",
  CANCEL_THIS_BOOKING: "Do you really want to cancel the reservation?",
  CONFIRM_UPDATE_BOOKING: "Do you really want to change this reservation ?",
  CONFIRM_UPDATE_BOOKING_WARNING: "Changes made to this reservation impact the total cost.",
  BOOKING_CANCELLED: "Reservation cancelled",
  DATES_NOT_AVAILABLE: "Please note that this location is partially available for the selected dates. Please check the calendar."
};
