export default {
  REPORTS: "Reports",
  STATISCTICS_LIST: "Report and stats",
  PAYMENT_STATISTICS: "Payments Report",
  PAYMENTS_LIST: "Payments List",
  REPORT_OCC:"Rapport d'ocuppation",
  OWNER: "Owner",
  CAPACITY: "Capacity",
  CAPACITY_RESERVED: "Reserved",
  RESERVED: "Nights Reserved",
  INCOME: "Income",
  NIGHTS: "Nights",
  TOTAL_SPOTS:"Total spots",
  TOTAL_OCC_SPOTS:"Total occupied spots",
  AVG_LENGHT_OCC:"Average length of occupancy",
  OVERALL_OCC_RATE:"Overall occupancy rate",
  ADR: "Average Price",
  RESERVATION_RATE: "Reservation %",
  RESERVED_SPOTS: "Reserved Rooms",
  RESERVED_PERC: "Occupation Rate (%)",
  REVENUES: "Revenues",
  TOTAL_REVENUE: "Location Revenues",
  MONTH: "Month",
  JANUARY: "January",
  FEBRUARY: "February",
  MARCH: "March",
  APRIL: "April",
  MAY: "May",
  JUNE: "June",
  JULY: "July",
  AUGUST: "August",
  SEPTEMBER: "September",
  OCTOBER: "October",
  NOVEMBER: "November",
  DECEMBER: "December",
  AVAILABLE_SPOTS: "Available Spots",
  DATE_HOUR: "Date & Hour",
  TOTAL_REC_PAYMENTS: "Total received payments",
  ARRIVING_DATE: "Arriving date"
};
